import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-screen-md" }
const _hoisted_2 = { class: "flex items-center justify-center flex-wrap" }
const _hoisted_3 = { class: "mt-3 text-primary w-full text-center font-semibold" }
const _hoisted_4 = { class: "grid md:grid-cols-2 gap-x-5 gap-y-0 mt-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_logo = _resolveComponent("company-logo")!
  const _component_base_file_input = _resolveComponent("base-file-input")!
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_base_form_app = _resolveComponent("base-form-app")!
  const _component_teleport_to_content_footer = _resolveComponent("teleport-to-content-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_form_app, { onSubmit: _ctx.submit }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_base_form_group, {
            class: "my-3 border-dashed border-4 max-w-xs mx-auto",
            "input-type": "custom",
            "invalid-message": _ctx.validationErrors.logo
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_file_input, {
                modelValue: _ctx.form.logo,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.logo) = $event)),
                accept: ".png,.jpg,.jpeg"
              }, {
                default: _withCtx(({ imageURL }) => [
                  _createElementVNode("div", _hoisted_2, [
                    imageURL
                      ? (_openBlock(), _createBlock(_component_company_logo, {
                          key: 0,
                          src: imageURL
                        }, null, 8, ["src"]))
                      : (_openBlock(), _createBlock(_component_company_logo, { key: 1 })),
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.trans("layout.change_company_logo")), 1)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["invalid-message"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_base_form_group, {
              required: "",
              label: _ctx.trans('layout.company.name'),
              modelValue: _ctx.form.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
              "invalid-message": _ctx.validationErrors.name
            }, null, 8, ["label", "modelValue", "invalid-message"]),
            _createVNode(_component_base_form_group, {
              required: "",
              label: _ctx.trans('layout.company.siupal_or_siuppak'),
              modelValue: _ctx.form.siupal_or_siuppak,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.siupal_or_siuppak) = $event)),
              "invalid-message": _ctx.validationErrors.siupal_or_siuppak
            }, null, 8, ["label", "modelValue", "invalid-message"]),
            _createVNode(_component_base_form_group, {
              required: "",
              label: _ctx.trans('layout.company.phone_number'),
              modelValue: _ctx.form.phone_number,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.phone_number) = $event)),
              "invalid-message": _ctx.validationErrors.phone_number
            }, null, 8, ["label", "modelValue", "invalid-message"]),
            _createVNode(_component_base_form_group, {
              required: "",
              label: _ctx.trans('layout.company.website'),
              modelValue: _ctx.form.website,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.website) = $event)),
              "invalid-message": _ctx.validationErrors.website
            }, null, 8, ["label", "modelValue", "invalid-message"]),
            _createVNode(_component_base_form_group, {
              class: "col-span-2",
              "input-type": "textarea",
              required: "",
              label: _ctx.trans('layout.company.address'),
              modelValue: _ctx.form.address,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.address) = $event)),
              "invalid-message": _ctx.validationErrors.address
            }, null, 8, ["label", "modelValue", "invalid-message"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"]),
    _createVNode(_component_teleport_to_content_footer)
  ], 64))
}