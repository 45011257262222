
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import { defineComponent } from "vue";
import authStore from "@/store/auth";
import authHttp from "@/http/auth";
import { helperMixin } from "@/mixins";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";
import { CompanySave } from "@/models/company";

export default defineComponent({
  mixins: [helperMixin],
  components: {
    BaseFormApp,
    BaseFormGroup,
    CompanyLogo,
    TeleportToContentFooter,
    BaseFileInput
  },
  data() {
    const auth = authStore();

    return {
      validationErrors: {} as any,
      form: {
        name: auth.state.user.company?.name,
        siupal_or_siuppak: auth.state.user.company?.siupal_or_siuppak,
        address: auth.state.user.company?.address,
        phone_number: auth.state.user.company?.phone_number,
        website: auth.state.user.company?.website
      } as CompanySave
    };
  },
  computed: {
    authStore,
    authHttp
  },
  methods: {
    async submit() {
      this.validationErrors = {};

      const { response } = (await this.requestHandler(this.authHttp.updateCompany(this.form)) ?? { response: null});

      if (response) {
        this.authStore.state.user.company = response.data;
      }

      this.validationErrors = this.authHttp.validationErrors;
    }
  }
});
